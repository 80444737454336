(function($) {
	
$(document).ready(function () {
	/*$('.language-selection').click(function(){
		if($('body').hasClass("arabic")) {
			$('body').removeClass('arabic');
			$('body').addClass('english');
		} else {
			$('body').removeClass('english');
			$('body').addClass('arabic');
		}
	});*/
	//--------------------
	/*$('.formcontrol').each(function(){
		if ($(this).val()){
		  $(this).closest('.form-group').addClass("quote");
		}
	});

	$(".form-group .formcontrol").on('keyup focusout',function() {
		if ($(this).val().length === 0) {
			$(this).closest('.form-group').removeClass('quote').removeClass('active-form');
		} else {
			$(this).closest('.form-group').addClass('quote').addClass('active-form');
		}
	});*/
	$('.baner-carosal').slick({ 
        slidesToShow: 1,
        dots: true,
        autoplay: true, 
        autoplaySpeed: 2000,
        slidesToScroll: 1,
        pauseOnHover: true,  
        nav: true,
        draggable: true,
        swipe: true,
        touchMove: true,
        arrows: true,
		fade: true,
        appendArrows: $('.feacourse-mn .mn-h2 span')
    });
	
	//TEACHER LIPS
	setInterval(function(){ 
		//$('.teacher-lips').toggleClass('non-lip');
		setTimeout(function(){
			$('.teacher-lips').toggleClass('non-lip');
		},50);
	},500); 
	
	//TEACHER EYE
	setInterval(function(){ 
		setTimeout(function(){
			$('.techeye1').toggleClass('offeye').delay(10000);
			$('.techeye2').toggleClass('speep').delay(10000);  
		});
		setTimeout(function(){
			//$('.teacher-lips').toggleClass('non-lip');
		});
	},800); 
	
	//MOTHER EYE
	setInterval(function(){ 
	setTimeout(function(){
		$('.mothereye-up').toggleClass('speep');
		$('.mothereye-down').toggleClass('speep');  
	});
	setTimeout(function(){
		//$('.teacher-lips').toggleClass('non-lip');
	});
	},700); 
	
	//MOTHER LIPS
	setInterval(function(){ 
		$('.mother-lips').toggleClass('non-lip');
	},2000); 

	//FATHER LIPS
	setInterval(function(){ 
		$('.fr-lip1').toggleClass('non-lip');
		$('.fr-lip2').toggleClass('lip-on');
	},1000);
	
	//TOGGLE CHIELD LIST  
	$('.drop-link').click(function(e){
		e.stopPropagation();
		$(this).toggleClass('open');  
	});
	$('.profile-control').click(function(e){
		e.stopPropagation();
		$('.dropdown-profile').toggleClass('open');
	});

	//--REMOVE TOGGLE CLASS
	$(document).click(function() {
		$('.drop-link').removeClass('open');  
		$('.dropdown-profile').removeClass('open');
	});

	$(".page-popup").fancybox();
	
	//IMAGE BG
	$(".div-bg").each( function(){
		var imageUrl = $(this).find('img').attr("src");			
		$(this).find('img').css("visibility","hidden");			
		$(this).css('background-image', 'url(' + imageUrl + ')').css("background-repeat","no-repeat").css("background-size","cover").css("background-position","50% 50%");		
	});
	
	//TYPEING Style
	/*if ($('.sliderContent.text').length) {
		var typed = new Typed('#typed', {
			stringsElement: '#typed-strings',
			typeSpeed: 100,
			backDelay: 1000,
			backSpeed: 100,  
			loop: true,
		});
	}*/
});
$(window).scroll(function(){
	
});	

if( $(window).width() > 767 ){

}else{

}
$(window).on('load', function () {

});
})(window.jQuery);


 